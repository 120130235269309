import { Link, Typography } from "@mui/material";
import InfoCard from "components/InfoCard";
import React from "react";
import { isLifestyle } from "utils";
import { ErrorPageTemplate } from "./ErrorPageTemplate";

// TODO: Figure out how to handle back browser button clicks / allow retries after this page is rendered

export const NotFoundPage: React.VFC = () => {
    return (
        <ErrorPageTemplate>
            <InfoCard variant="error">
                <Typography variant={"h2"} sx={{ color: "ractRed", fontWeight: 500 }} gutterBottom>
                    Sorry, the page you’re looking for can’t be found.
                </Typography>
                <Typography variant={"body1"} gutterBottom>
                    Head back to the <Link href={"/"}>join {isLifestyle() ? "lifestyle" : "roadside"}</Link> form to
                    enter your details and complete your purchase.
                </Typography>
            </InfoCard>
        </ErrorPageTemplate>
    );
};
