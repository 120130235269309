import { Box, Link, Typography } from "@mui/material";
import InfoCard from "components/InfoCard";
import React from "react";
import { ErrorPageTemplate } from "./ErrorPageTemplate";

export const SomethingWentWrongPage: React.VFC = () => {
    return (
        <ErrorPageTemplate>
            <Box sx={{ mt: 4 }}>
                <InfoCard variant={"error"}>
                    <Typography variant={"h2"} sx={{ color: "ractRed", fontWeight: 500 }} gutterBottom>
                        Apologies.
                        <br />
                        Something has gone wrong.
                    </Typography>
                    <Typography variant={"body1"} gutterBottom>
                        We’re unfortunately experiencing some technical issues.
                    </Typography>
                    <Typography variant={"body1"}>
                        Please call us on <Link href={"tel:132722"}>13 27 22</Link> to complete your purchase.
                    </Typography>
                </InfoCard>
            </Box>
        </ErrorPageTemplate>
    );
};
