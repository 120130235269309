import { GetProductsResponse, GetTitlesResponse, MembershipPurchaseRequest, MembershipPurchaseResponse } from "./Types";
import config from "../../config";
import { APIServiceError } from "components/AppErrors";

// Can make this into a hook (useAPIService) to make it cleaner and support raising errors from the various operations
// At this point we throw in two places, one if we don't get a response.ok, and another if fetch fails maybe due to a network error
// They allow us used the CustomError

export const getProducts = async (): Promise<GetProductsResponse> => {
    try {
        const response = await fetch(`${config.roadsideApi.baseUrl}api/Products`);
        if (!response.ok) {
            console.error(response.status);
            throw new APIServiceError(await response.text());
        }
        return (await response.json()) as GetProductsResponse;
    } catch (error: any) {
        throw new APIServiceError(error.message);
    }
};

export const getTitles = async (): Promise<GetTitlesResponse> => {
    try {
        const response = await fetch(`${config.roadsideApi.baseUrl}api/Titles`);
        if (!response.ok) {
            throw new APIServiceError(await response.text());
        }
        return (await response.json()) as GetTitlesResponse;
    } catch (error: any) {
        throw new APIServiceError(error.message);
    }
};

export const postMembershipPurchase = async (
    request: MembershipPurchaseRequest
): Promise<MembershipPurchaseResponse> => {
    try {
        const response = await fetch(`${config.roadsideApi.baseUrl}api/MembershipPurchase`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
        });

        if (!response.ok) {
            throw new APIServiceError(await response.text());
        }

        return (await response.json()) as MembershipPurchaseResponse;
    } catch (error: any) {
        throw new APIServiceError(error.message);
    }
};
