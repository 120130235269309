import create from "zustand";
import { CoverSelectionState, PaymentState, PersonalDetailsState, State } from "./Types";
import { getProducts, getTitles, MembershipPurchaseRequest, postMembershipPurchase } from "services/Api";
import { devtools } from "zustand/middleware";
import { Gender, LifestyleProduct, PaymentFrequency, PaymentMedia, PaymentType, RoadsideProduct } from "CommonTypes";
import { enrichAddress, formatAddress } from "services/QASService";
import { format } from "date-fns";
import { capitalize, isLifestyle } from "utils";

export const useStore = create<State>(
    devtools((set, get) => ({
        started: false,
        coverSelection: {
            product: isLifestyle() ? LifestyleProduct.lifestyle : RoadsideProduct.ultimate,
            paymentFrequency: isLifestyle() ? PaymentFrequency.yearly : PaymentFrequency.monthly,
        },
        personalDetails: {
            title: "",
            givenNames: "",
            surname: "",
            gender: "",
            dateOfBirth: undefined,
            residentialAddress: undefined,
            emailAddress: "",
            preferredPhone: "",
            postalAddress: undefined,
        },
        payment: {
            type: PaymentMedia.card,
            creditCard: undefined,
            directDebit: undefined,
        },
        termsAndConditionsChecked: false,
        temporaryMemberNumber: "",

        setStarted: () =>
            set(
                (state: State) => ({
                    ...state,
                    started: true,
                }),
                false,
                "setStarted"
            ),
        setCoverSelection: (coverSelection: CoverSelectionState) =>
            set(
                (state: State) => ({
                    ...state,
                    coverSelection: { ...state.coverSelection, ...coverSelection },
                }),
                false,
                "setCoverSelection"
            ),
        setPersonalDetails: (personalDetails: PersonalDetailsState) =>
            set(
                (state: State) => ({
                    ...state,
                    personalDetails: personalDetails,
                }),
                false,
                "setPersonalDetails"
            ),
        setPayment: (payment: PaymentState) =>
            set(
                (state: State) => ({
                    ...state,
                    payment: payment,
                }),
                false,
                "setPayment"
            ),
        setTermsAndConditionsChecked: (termsAndConditionsChecked: boolean) =>
            set(
                (state: State) => ({
                    ...state,
                    termsAndConditionsChecked: termsAndConditionsChecked,
                }),
                false,
                "setTermsAndConditionsChecked"
            ),

        pricing: {
            result: undefined,
            fetch: async () => {
                //TODO handle errors
                set((state: State) => ({
                    ...state,
                    pricing: {
                        ...state.pricing,
                        loading: true,
                    },
                }));
                const products = await getProducts();
                set((state: State) => ({
                    ...state,
                    pricing: {
                        ...state.pricing,
                        loading: false,
                        result: {
                            Advantage: {
                                Monthly: products.Pricing.find(
                                    (x) =>
                                        x.Product === RoadsideProduct.advantage &&
                                        x.PaymentFrequency === PaymentFrequency.monthly
                                )!.Price,
                                Yearly: products.Pricing.find(
                                    (x) =>
                                        x.Product === RoadsideProduct.advantage &&
                                        x.PaymentFrequency === PaymentFrequency.yearly
                                )!.Price,
                            },
                            Ultimate: {
                                Monthly: products.Pricing.find(
                                    (x) =>
                                        x.Product === RoadsideProduct.ultimate &&
                                        x.PaymentFrequency === PaymentFrequency.monthly
                                )!.Price,
                                Yearly: products.Pricing.find(
                                    (x) =>
                                        x.Product === RoadsideProduct.ultimate &&
                                        x.PaymentFrequency === PaymentFrequency.yearly
                                )!.Price,
                            },
                            Lifestyle: {
                                Monthly:
                                    products.Pricing.find(
                                        (x) =>
                                            x.Product === LifestyleProduct.lifestyle &&
                                            x.PaymentFrequency === PaymentFrequency.monthly
                                    )?.Price || 0,
                                Yearly:
                                    products.Pricing.find(
                                        (x) =>
                                            x.Product === LifestyleProduct.lifestyle &&
                                            x.PaymentFrequency === PaymentFrequency.yearly
                                    )?.Price || 0,
                            },
                        },
                    },
                }));
            },
            loading: false,
        },

        titles: {
            result: undefined,
            fetch: async () => {
                //TODO handle errors
                set((state: State) => ({
                    ...state,
                    titles: {
                        ...state.titles,
                        loading: true,
                    },
                }));
                const titles = await getTitles();
                set((state: State) => ({
                    ...state,
                    titles: {
                        ...state.titles,
                        loading: false,
                        result: titles.Titles.map((t) => ({
                            title: capitalize(t.Title),
                            gender: t.Gender,
                            genderSpecific: t.GenderSpecific,
                        })),
                    },
                }));
            },
            loading: false,
        },

        purchase: {
            post: async () => {
                //TODO handle errors
                set((state: State) => ({
                    ...state,
                    purchase: {
                        ...state.purchase,
                        loading: true,
                    },
                }));
                const state = get();
                const residentialAddress = await formatAddress(state.personalDetails.residentialAddress?.format!);
                const residentialEnrich = await enrichAddress(residentialAddress.result?.global_address_key!);
                const postalAddress = state.personalDetails.postalAddress?.format
                    ? await formatAddress(state.personalDetails.postalAddress?.format)
                    : undefined;
                const postalEnrich = postalAddress
                    ? await enrichAddress(postalAddress.result?.global_address_key!)
                    : undefined;
                const postalAddressDelivery = !!postalAddress?.result?.components?.delivery_service?.full_name;
                const request: MembershipPurchaseRequest = {
                    MemberDetails: {
                        Title: state.personalDetails.title.toUpperCase(),
                        FirstName: state.personalDetails.givenNames,
                        LastName: state.personalDetails.surname,
                        Gender: state.personalDetails.gender.toUpperCase() as Gender,
                        DateOfBirth: format(state.personalDetails.dateOfBirth!, "dd/MM/yyyy"),
                        Email: state.personalDetails.emailAddress,
                        HomePhone: !state.personalDetails.preferredPhone.startsWith("04")
                            ? state.personalDetails.preferredPhone
                            : undefined,
                        WorkPhone: undefined,
                        MobilePhone: state.personalDetails.preferredPhone.startsWith("04")
                            ? state.personalDetails.preferredPhone
                            : undefined,
                        ResidentialAddress: {
                            Country: residentialAddress.result?.components?.country_iso_3,
                            PostCode: residentialAddress.result?.address?.postal_code,
                            // Related to {building?.building_name?} {sub_building?.door?.full_name?} ??
                            PropertyName: undefined,
                            State: residentialAddress.result?.address?.region,
                            StreetName: residentialAddress.result?.components?.street?.full_name,
                            // Notes for StreetNumber here and in PostalAddress
                            // Should probably discuss with Matterhorn and see how this maps to the API
                            // Have also noticed the full address lines to be of the form below
                            // Any undefined components wouldn't exist
                            // {building?.building_name?} {sub_building?.door?.full_name?} {building?.building_number} {street?.full_name?}

                            // Or just strip street?.full_name from the address lines to get the StreetNumber?
                            // API's regex validation doesn't like space in the street number??????
                            StreetNumber: [
                                residentialAddress.result?.components?.building?.building_name,
                                residentialAddress.result?.components?.sub_building?.door?.full_name,
                                residentialAddress.result?.components?.building?.building_number,
                            ]
                                .filter((x) => x && x.length > 0)
                                .join(" "),
                            Suburb: residentialAddress.result?.address?.locality,
                            Latitude: residentialEnrich.result?.aus_regional_geocodes?.latitude,
                            Longitude: residentialEnrich.result?.aus_regional_geocodes?.longitude,
                            Gpid: undefined,
                            Gnaf: residentialAddress?.metadata?.address_info?.identifier?.gnafPid,
                        },
                        PostalAddress: postalAddress
                            ? {
                                  Country: postalAddress.result?.components?.country_iso_3,
                                  PostCode: postalAddress.result?.address?.postal_code,
                                  // Related to {building?.building_name?} {sub_building?.door?.full_name?} ??
                                  PropertyName: undefined,
                                  State: postalAddress.result?.address?.region,
                                  StreetName: postalAddressDelivery
                                      ? ""
                                      : postalAddress.result?.components?.street?.full_name,
                                  // See notes for StreetNumber above in ResidentialAddress
                                  // Or if postalAddress is PO Box Address then {delivery_service?.full_name?}
                                  // API's regex validation doesn't like space in the street number??????
                                  StreetNumber: postalAddressDelivery
                                      ? postalAddress.result?.components?.delivery_service?.full_name
                                      : [
                                            postalAddress.result?.components?.building?.building_name,
                                            postalAddress.result?.components?.sub_building?.door?.full_name,
                                            postalAddress.result?.components?.building?.building_number,
                                        ]
                                            .filter((x) => x && x.length > 0)
                                            .join(" "),
                                  Suburb: postalAddress.result?.address?.locality,
                                  Latitude: postalEnrich?.result?.aus_regional_geocodes?.latitude,
                                  Longitude: postalEnrich?.result?.aus_regional_geocodes?.longitude,
                                  Gpid: undefined,
                                  Gnaf: postalAddress.metadata?.address_info?.identifier?.gnafPid,
                              }
                            : undefined,
                        ProductDetails: {
                            Product: state.coverSelection.product,
                            PaymentFrequency: state.coverSelection.paymentFrequency,
                        },
                        PaymentDetails: {
                            PaymentType:
                                // if the product is lifestyle always send paynow otherwise always send directdebit
                                state.coverSelection.product === LifestyleProduct.lifestyle
                                    ? PaymentType.payNow
                                    : PaymentType.directDebit,
                            CreditCardDetails: state.payment.creditCard
                                ? {
                                      CardName: "CARDHOLDER NAME",
                                      CardType: state.payment.creditCard.cardType,
                                      CardNumber: state.payment.creditCard.cardNumber,
                                      Cvv: state.payment.creditCard.cvv,
                                      ExpiryMonth: state.payment.creditCard.expiry.month,
                                      ExpiryYear: state.payment.creditCard.expiry.year,
                                  }
                                : undefined,
                            BankDetails: state.payment.directDebit
                                ? {
                                      Bsb: state.payment.directDebit.bsb,
                                      AccountNumber: state.payment.directDebit.accountNumber,
                                      AccountName: state.payment.directDebit.accountName,
                                  }
                                : undefined,
                        },
                        ConnectionDetails: {
                            IpAddress: "127.0.0.1", //TODO get their real IP? is this pointless?
                        },
                    },
                };
                const response = await postMembershipPurchase(request);
                set((state: State) => ({
                    ...state,
                    temporaryMemberNumber: response.TemporaryMemberNumber,
                    purchase: {
                        ...state.purchase,
                        loading: false,
                    },
                }));
            },
            loading: false,
        },
    }))
);
