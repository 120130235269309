import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useAppInsightsTracking } from "services/AppInsights";
import { NotFoundError } from "./Error";
import { ErrorBoundary } from "./ErrorBoundary";
import { NotFoundPage } from "./NotFoundPage";
import { SomethingWentWrongPage } from "./SomethingWentWrongPage";

// Consider replacing all throw new Error(...) with raiseError, where apprioprate

interface AppErrors {
    raiseError: (appError: Error) => void;
    // appError?: Error; // No longer exposing this as it is unused and only retriggers rendering of components that useAppErrors when an error is raised
}

interface ErrorAndCurrentPath {
    error: Error;
    currentPath: string;
}

export const ErrorContext = createContext<AppErrors | undefined>(undefined);

export const ErrorContextProvider: React.FC = ({ children }) => {
    // Thoughts
    // Error Boundaries?
    // Try and catch?
    // https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
    // https://reactjs.org/docs/error-boundaries.html#how-about-trycatch

    // Should maybe switch this to using window.location, or something so we don't have to move the router up? Or leave as is
    const location = useLocation();
    const [appError, setAppError] = useState<ErrorAndCurrentPath>();
    const { trackException } = useAppInsightsTracking();
    const raiseError = (error: Error) =>
        setAppError({
            error,
            currentPath: location.pathname,
        });

    if (appError) {
        console.error("An app error was raised", appError);
        trackException(appError);
    }

    useEffect(() => {
        if (appError && location.pathname !== appError.currentPath) {
            // Clear errors if the location changes and the path is different from the initial path the error was raised on
            setAppError(undefined);
        }
    }, [location, appError]);

    return (
        <ErrorContext.Provider value={{ raiseError }}>
            {appError && (appError.error instanceof NotFoundError ? <NotFoundPage /> : <SomethingWentWrongPage />)}
            {!appError && <ErrorBoundary raiseError={raiseError}>{children}</ErrorBoundary>}
        </ErrorContext.Provider>
    );
};

export const useAppErrors = () => {
    const appErrors = useContext(ErrorContext);
    if (appErrors === undefined) {
        throw new Error("useAppErrors must be used within the ErrorContextProvider :-P");
        // No one would catch this error for you now, since you decided to use the error catcher wrongly :-P
    }
    return appErrors;
};
