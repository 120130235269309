import { ApplicationInsights, ICustomProperties, IExceptionTelemetry } from "@microsoft/applicationinsights-web";
import { ReactPlugin, useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import config from "config";

export const AppInsightsReactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: config.appInsights.instrumentationKey,
        enableAutoRouteTracking: true,
        extensions: [AppInsightsReactPlugin],
    },
});

appInsights.loadAppInsights();

interface AppInsightsTracking {
    trackException: (exception: IExceptionTelemetry, customProperties?: ICustomProperties) => void;
    trackEvent: <T>(eventName: string, eventData: T, skipFirstRun?: boolean) => void;
}

export const useAppInsightsTracking = (): AppInsightsTracking => {
    const appInsightsContext = useAppInsightsContext(); // Or maybe just pass in the AppInsightsReactPlugin since we have it here
    const trackException = (exception: IExceptionTelemetry, customProperties?: ICustomProperties) =>
        appInsightsContext.trackException(exception, customProperties);
    // See if there are any differences between appInsightsContext.trackEvent and the useTrackEvent hook
    // Asides that there might be no hook for trackingExceptions or traces?
    const trackEvent = <T>(eventName: string, eventData: T /*, skipFirstRun?: boolean*/) =>
        appInsightsContext.trackEvent({ name: eventName }, eventData);
    // useTrackEvent(appInsightsContext, eventName, eventData, skipFirstRun);
    return {
        trackException,
        trackEvent,
    };
};
