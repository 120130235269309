import React, { useEffect } from "react";
import { NotFoundError } from "./Error";
import { useAppErrors } from "./ErrorContext";

interface RaiseErrorProps {
    message?: string;
}

// These can be used inside render methods or you can call the raiseError function directly outside render (maybe in an effect)

export const RaiseNotFound: React.VFC<RaiseErrorProps> = ({ message }) => {
    const { raiseError } = useAppErrors();

    useEffect(() => {
        raiseError(new NotFoundError(message));
    }, [raiseError, message]);
    return <></>;
};
