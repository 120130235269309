import { Box, Link, useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import { faPhone } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ResponsiveAll } from "../ThemeWrapper";

export interface HeaderProps {
    headerImage?: string;
    hideHeaderImage?: boolean;
}

export const Header: React.VFC<HeaderProps> = ({ headerImage, hideHeaderImage }) => {
    const theme = useTheme();

    let headerImageFitpDimensions = "fit=crop&w=1366&h=911";

    if (useMediaQuery(theme.breakpoints.only("xs"))) {
        headerImageFitpDimensions = "fit=crop&w=600&h=400";
    }
    if (useMediaQuery(theme.breakpoints.only("sm"))) {
        headerImageFitpDimensions = "fit=crop&w=900&h=600";
    }
    if (useMediaQuery(theme.breakpoints.only("md"))) {
        headerImageFitpDimensions = "fit=crop&w=1200&h=800";
    }

    const displayMobilePhone = !useMediaQuery(theme.breakpoints.down("lg"));

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    pl: ResponsiveAll("0", "25px", "25px", "75px", "75px"),
                    height: "100px",
                    [theme.breakpoints.down("lg")]: {
                        height: "72px",
                    },
                }}
            >
                <Box
                    sx={{
                        position: "relative",
                        bottom: "-21px",
                        zIndex: 2, // Has to be above blue bar
                        width: "88px",
                        height: "88px",
                        [theme.breakpoints.down("lg")]: {
                            width: "60px",
                            height: "60px",
                            bottom: "-16px",
                        },
                    }}
                >
                    <img src="https://www.datocms-assets.com/49357/1625074732-ract-logo.svg" alt="RACT" />
                </Box>
                <Box>
                    <Link
                        href={"tel:132722"}
                        sx={{
                            color: "inherit",
                            textDecoration: "none",
                            typography: "body1",
                            fontSize: 35,
                            lineHeight: "32px",
                            fontWeight: "600",
                            [theme.breakpoints.down("md")]: {
                                fontSize: 35,
                                lineHeight: "32px",
                            },
                        }}
                    >
                        {" "}
                        <FontAwesomeIcon
                            icon={faPhone}
                            style={{
                                marginRight: 10,
                                height: 25,
                                width: 25,
                            }}
                            transform={{ rotate: -90 }}
                        />{" "}
                        {displayMobilePhone && `13 27 22`}
                    </Link>
                </Box>
            </Box>
            <Box
                sx={{
                    position: "relative",
                    width: ResponsiveAll(
                        "calc(100% + 25px)",
                        "calc(100% + 25px)",
                        "calc(100% + 50px)",
                        "calc(100% + 50px)",
                        "100%"
                    ),
                    left: ResponsiveAll("-25px", "-25px", "-50px", "-50px", "0"),
                }}
            >
                <Box
                    sx={{
                        backgroundColor: "#2376D8",
                        boxSizing: "border-box",
                        height: "49px",
                        [theme.breakpoints.down("lg")]: {
                            height: "30px",
                        },
                    }}
                />
                {!hideHeaderImage && (
                    <Box
                        sx={{
                            background: `url("${headerImage}?${headerImageFitpDimensions}")`,
                            backgroundPosition: "center",
                            backgroundPositionY: ResponsiveAll("275px", "375px", "475px", "575px", "625px"),
                            borderBottomRightRadius: ResponsiveAll("50px", "50px", "70px", "70px", "70px"),
                            width: "100%",
                            height: ResponsiveAll("200px", "240px", "300px", "400px", "500px"),
                        }}
                    />
                )}
            </Box>
        </Box>
    );
};
